<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">{{ title }}</h3>
        </div>
        <div class="col text-right">
          <!-- <base-button size="sm" type="primary">Ver todos</base-button> -->
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table thead-classes="thead-light" :data="tableData">
        <template v-slot:columns>
          <th>Nombre Equipo</th>
          <th>Ubicacion</th>
          <th class="mr-2">Días garantía</th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            {{ row.item.name }}
          </th>
          <td>
            {{ row.item.location }}
          </td>
          <td>
            <div class="d-flex align-items-center">
              <span class="mr-2">{{ row.item.dias }}</span>
<!-- 
              <span class="mr-2">{{ row.item.progress }}%</span>
              <base-progress
                :type="row.item.progressType"
                class="pt-0"
                :show-percentage="false"
                :value="row.item.progress"
              />
 -->
            </div>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "tabla-estados-equipos",

  props: {
    title: {
      type: String,
      default: "Equipos",
    },

    tableData: {
      type: Array,
      default: [],
    },    
  },

};
</script>
<style></style>
