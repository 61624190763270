<template>
  <div>
<!-- 
    <base-header 
      style="background-size: 100% 500px;background-image:linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.2)), url('img/industry.jpg');" 
      class="pb-6 pb-8 pt-5 pt-md-8">
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    </base-header>
 -->

<!-- Transelec
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        max-height: 150px;
        background-image: url(img/layout/transelec/industry.jpg);
        background-size: cover;
        background-position: center center;
      "
    >
 -->

    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        max-height: 150px;
        background-image: url('img/layout/codelco/chuqui_users.png');
        background-size: cover;
        background-position: center center;
      "
    >

      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">

          <DataTable
            :value="fullTable"
            :paginator="true"
            class="p-datatable-customers"
            :rows="10"
            :rowsPerPageOptions="[10, 25, 50]"
            v-model:filters="filters"
            filterDisplay="menu"
            :loading="loading"
            :globalFilterFields="[
              'Detalles',
              'Contraseña',
              'user',
              'fullName',
              'type',
              // 'Detalles',
              // 'Contraseña',
              // 'Correo',
              // 'Nombre de Usuario',
              // 'Tipo',
            ]"

            responsiveLayout="scroll"
          >
            <template #header>
              <div class="p-d-flex p-jc-between p-ai-center">
                <h5 class="p-m-0">Listado de usuarios</h5>
                <span class="p-input-icon-left">
                  <i class="pi pi-search"/>
                  <InputText v-model="filters['global'].value" placeholder="Buscar" />
                </span>
              </div>
            </template>
            <Column field="itemN" header="nº" :sortable="true">
              <!-- <template #body="body">
                <base-button
                  v-model="itemN"
                  size="sm"
                  type="secondary"
                  :id="body.data.key"
                  v-on:click="select($event)"
                >
                  {{ body.data.itemN }}
                </base-button>
              </template> -->
            </Column>


            <!-- <Column field="ID" header="Identificador {{ this.clientName }}"></Column> -->
            <!-- <Column field="numeroSerie" header="Número de serie"></Column> -->
            <!-- <Column field="Detalles" :sortable="true" header="Detalles"></Column> -->
            <!-- <Column field="restore_passsword" header="Contraseña"></Column> -->
            <Column field="user" header="Usuario"></Column>
            <Column field="fullName" header="Nombre">
            </Column>
            <Column field="type" :sortable="true" header="Tipo">
              <template #body="body"><span>{{body.data.roleName}}</span></template>
            </Column>
<!-- 
            <Column field="categoria" :sortable="true" header="Categoría Equipo"></Column>
            <Column field="falla" :sortable="true" header="Estado">
              <template #body="body">
                <div v-if="body.data.falla" class="text-center">
                  <i class="pi pi-exclamation-circle ml-2 mx-1" style="color: red;"/>
                  <span style="color: red;">Falla</span>
                </div>
                <div v-else class="text-center">operativo</div>
              </template>
            </Column>
-->

<!-- 
            <Column field="expiracionGarantia" header="Expiración Garantía"></Column>
            <Column field="listImagenes" header="Fotos Equipo"></Column>
            <Column field="listAdjuntos" header="Manuales Técnicos"></Column>
            <Column field="listDocumentos" header="Documentos administrativos"></Column>

            <Column field="criticidad" header="Criticidad"></Column>
-->
          </DataTable>
<!-- 
          <qrcode-vue
            value="http://localhost:8080/#/dashboard"
            :size="200"
            level="H"
          />
-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase";
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import QrcodeVue from 'qrcode.vue';
import { getUsers } from "@/model/functions";
// import { setAgentesGarantias } from "@/model/functions";

export default {
  name: "tables",
  components: {
    DataTable,
    Column,
    InputText,
    QrcodeVue,
  },

  data() {
    return {
      useremail: "",
      totalitems: 0,
      totalEnFalla: 0,
      totalCommissioning: 0,
      total1YearOperation: 0,
      totalCapital: 0,

      filters: {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'itemN': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'nombreEquipo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'descripcion': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'fabricante': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'modelo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'ubicacion': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'fechaPES': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'fechaFinGarantia': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'categoria': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
      },

      loading: false,
      fullTable: [],
    };
  },

  methods: {
    select: function (event) {
      var targetId = event.currentTarget.id;
      console.log(targetId); // returns 'foo'
      this.$router.push("/element/" + targetId);
    },

    initTables() {
      console.log("<·><><·> initTables() <·><><·>");
      var count = 0;
      var countSpareCommissioning = 0;
      var count1YearOperation = 0;
      var countCapital = 0;

      getUsers(this.db_ref_users, (datos) => {
        this.fullTable = datos;

        this.fullTable.forEach(ArrayDB => {
          switch (ArrayDB.type) {
            case 1:
              ArrayDB.roleName = "Administrador"
              break;
            case 2:
              ArrayDB.roleName = "Operador"
              break;
            case 3:
              ArrayDB.roleName = "Encargado Garantías"
              break;
            case 4:
              ArrayDB.roleName = "Gestor Información"
              break;

            default:
              ArrayDB.roleName = "Operador"
              break;
          }
        });
      });
    },
  },

  created() {

    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.useremail = user.email;
        console.log("USER SIGNED");
        console.log("this->", this);
        this.initTables();
      } else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push("/login");
      }
    });
  },
};
</script>
<style></style>
