<template>
  <div>
<!--     
    <base-header 
      class="pb-6 pb-8 pt-5 pt-md-8">
      style="
        background-size: 1200px 500px;
        background-image:linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)), url('img/sunset.jpg');" 
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    </base-header>
 -->
<!-- Transelec
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        max-height: 150px;
        background-image: url(img/layout/transelec/sunset.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
 -->
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        max-height: 150px;
        background-image: url(img/layout/codelco/el_teniente_tunel.jpg);
        background-size: cover;
        background-position: center center;
      "
    >

      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    </base-header>

    <Dialog
      :header="titleModalSolicitud"
      v-model:visible="showModalDetalleSolicitud"
      :breakpoints="{'960px': '90vw', '640px': '100vw'}" :style="{width: '75vw'}"
    >
      <div class="row">
        <div class="col-auto">
          <table class="col-md-offset-2 my-4">
            <tbody>
              <tr><td class="col-md-4"><b>Fecha:</b></td><td class="col-md-8">{{ formatDate(dataSolicitud.fecha, 'DD-MM-YYYY') }}</td></tr>
              <tr>
                <td class="col-md-4"><b>Estado:</b></td>
                <td class="col-md-8">
                  <SelectButton v-model="estadoSolicitud" :options="estadosSolicitudes" optionLabel="name" />
                </td>
              </tr>
              <tr><td class="col-md-4"><b>Asignada a:</b></td><td class="col-md-8">{{dataSolicitud.ingenieroContrato}} / {{dataSolicitud.mailIngenieroContrato}}</td></tr>
              <tr><td class="col-md-4"><b>Reportado por:</b></td><td class="col-md-8">{{dataSolicitud.reportadoPor}}</td></tr>
              <tr><td class="col-md-4"><b>Descripción:</b></td><td class="col-md-8">{{dataSolicitud.reporteFalla}}</td></tr>
            </tbody>
          </table>
          <table class="col-md-offset-2 my-4">
            <tbody>
              <tr><td class="col-md-4"><b>Identificador {{ this.clientName }}</b></td><td class="col-md-8">{{dataSolicitud.ID}}</td></tr>
              <!-- <tr><td class="col-md-4"><b>Número de serie</b></td><td class="col-md-8">{{dataSolicitud.numeroSerie}}</td></tr> -->
              <tr><td class="col-md-4"><b>Nombre del Equipo</b></td><td class="col-md-8">{{dataSolicitud.nombreEquipo}}</td></tr>
              <tr><td class="col-md-4"><b>Descripción del Equipo</b></td><td class="col-md-8">{{dataSolicitud.descripcion}}</td></tr>
              <!-- <tr><td class="col-md-4"><b>Fabricante</b></td><td class="col-md-8">{{dataSolicitud.fabricante}}</td></tr> -->
              <!-- <tr><td class="col-md-4"><b>Proveedor</b></td><td class="col-md-8">{{dataSolicitud.proveedor}}</td></tr> -->
              <!-- <tr><td class="col-md-4"><b>Modelo Equipo</b></td><td class="col-md-8">{{dataSolicitud.modelo}}</td></tr> -->
              <tr><td class="col-md-4"><b>Ubicación</b></td><td class="col-md-8">{{dataSolicitud.ubicacion}}</td></tr>
              <tr><td class="col-md-4"><b>Área Responsable</b></td><td class="col-md-8">{{dataSolicitud.areaResponsable}}</td></tr>
              <!-- <tr><td class="col-md-4"><b>Comentarios (tension kV)</b></td><td class="col-md-8">{{dataSolicitud.comentarios}}</td></tr> -->
              <tr><td class="col-md-4"><b>Identificador contrato</b></td><td class="col-md-8">{{dataSolicitud.idContrato}}</td></tr>
              <!-- <tr><td class="col-md-4"><b>Año fabricacion</b></td><td class="col-md-8">{{dataSolicitud.anoFabricacion}}</td></tr> -->
              <!-- <tr><td class="col-md-4"><b>Fecha de PES</b></td><td class="col-md-8">{{dataSolicitud.fechaPES}}</td></tr> -->
              <!-- <tr><td class="col-md-4"><b>Meses</b></td><td class="col-md-8">{{dataSolicitud.mesesGarantia}}</td></tr> -->
              <!-- <tr><td class="col-md-4"><b>Fecha garantía hasta</b></td><td class="col-md-8">{{ dataSolicitud.fechaFinGarantia }}</td></tr> -->
              <!-- <tr><td class="col-md-4"><b>Expiración Garantía</b></td><td class="col-md-8">{{dataSolicitud.expiracionGarantia}}</td></tr> -->
              <!-- <tr><td class="col-md-4"><b>Fotos Equipo</b></td><td class="col-md-8">{{dataSolicitud.listImagenes}}</td></tr> -->
              <!-- <tr><td class="col-md-4"><b>Manuales Técnicos</b></td><td>
                <base-button
                  v-model="LinkArchivo"
                  icon="pi pi-paperclip"
                  size="sm"
                  type="secondary"
                  :id="JSON.stringify(dataSolicitud.listAdjuntos)"
                  @click="showUploadFiles($event)"></base-button>
              </td></tr> -->
              <!-- <tr><td class="col-md-4"><b>Documentos administrativos</b></td><td class="col-md-8">{{dataSolicitud.listDocumentos}}</td></tr> -->
              <tr><td class="col-md-4"><b>Categoría Equipo </b></td><td class="col-md-8">{{dataSolicitud.categoria}}</td></tr>
              <!-- <tr><td class="col-md-4"><b>Criticidad</b></td><td class="col-md-8">{{dataSolicitud.criticidad}}</td></tr> -->
              <tr><td class="col-md-4"><b>Fecha garantía hasta</b></td><td class="col-md-8">{{ formatDate(dataSolicitud.fechaFinGarantia, 'DD-MM-YYYY') }}</td></tr>
            </tbody>
          </table>
          <Panel header="Editar carta" :toggleable="true" :collapsed="true" :loading="loadingData">
            <div id="app" style="background-color: white">
            <!-- <vue-editor id="editor" v-model="content" :editor-toolbar="customToolbar"></vue-editor> -->
              <vue-editor id="editor" v-model="content"></vue-editor>

            <!-- editor oculto, permite que al imprimir  -->
            <div v-show="false">
              <vue-document-editor v-model:content="contentWrap" /> <!-- Vue 3 syntax -->
              <!-- <vue-document-editor :content.sync="[content]"/> -->
            </div>
            </div>
            <div class="my-2">
            <Button class="p-button-raised p-button-info mx-2" label="Guardar" @click="procesarSolicitud(dataSolicitud.key)"/>
            <Button class="p-button-raised p-button-info mx-2" label="Imprimir carta" @click="print"/>            
            </div>
          </Panel>
        </div>
      </div>
    </Dialog>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <!-- Card stats -->
          <div class="row">
            <div class="col-xl-3 col-lg-6">
              <stats-card
                title="Solicitudes pendientes"
                type="gradient-green"
                v-bind:sub-title="numSolPendientes"
                icon="ni ni-active-40"
                class="mb-4 mb-xl-0"
              >
                <template v-slot:footer>
                  <!-- <span class="text-nowrap">&nbsp;</span> -->
                </template>
              </stats-card>
            </div>
            <div class="col-xl-3 col-lg-6">
              <stats-card
                title="Solicitudes en proceso"
                type="gradient-orange"
                v-bind:sub-title="numSolEnProceso"
                icon="ni ni-chart-pie-35"
                class="mb-4 mb-xl-0"
              >
                <template v-slot:footer>
                  <!-- <span class="text-nowrap">&nbsp;</span> -->
                </template>
              </stats-card>
            </div>
            <div class="col-xl-3 col-lg-6">
              <stats-card
                title="Solicitudes resueltas"
                type="gradient-red"
                v-bind:sub-title="numSolResueltas"
                icon="ni ni-money-coins"
                class="mb-4 mb-xl-0"
              >
              </stats-card>
            </div>
<!-- 
            <div class="col-xl-3 col-lg-6">
              <stats-card
                title="Duracion promedio garantías"
                type="gradient-info"
                sub-title="18.0 meses"
                icon="ni ni-chart-bar-32"
                class="mb-4 mb-xl-0"
              >
              </stats-card>
            </div>
-->
          </div>

          <DataTable
            :value="solicitudesPendientes"
            :paginator="true"
            class="p-datatable-customers"
            :rows="10"
            :rowsPerPageOptions="[10, 25, 50]"
            v-model:filters="filters"
            filterDisplay="menu"
            :loading="loading"
            :globalFilterFields="[
              'fecha',
              'fechaFalla',
              'nombreEquipo',
              'fabricante',
              'proveedor',
              'ubicacion',
              'fechaFinGarantia',
              'ingenieroContrato',
              'estado',
            ]"
            responsiveLayout="scroll"
            sortField="fecha" :sortOrder="-1"
          >
            <template #header>
              <div class="p-d-flex p-jc-between p-ai-center">
                <h5 class="p-m-0">Solicitudes recibidas</h5>
                <span class="p-input-icon-left mr-3">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar" />
                </span>
              </div>
            </template>
            <Column field="fecha" :sortable="true" header="Fecha">
              <template #body="body">
                <div class="text-center">
                  <span>{{ formatDate(body.data.fecha, 'DD-MM-YYYY') }}</span>
                </div>
              </template>
            </Column>
            <Column header="nº solicitud" :sortable="true">
              <template #body="body">
                <base-button
                  size="sm"
                  type="secondary"
                  :id="body.data.key"
                  v-on:click="openSolicitud($event)"
                >
                  {{ body.data.numSolicitud }}
                </base-button>
              </template>
            </Column>
            <Column field="nombreEquipo" header="Nombre del Equipo"></Column>
            <!-- <Column field="fabricante" header="Fabricante"></Column> -->
            <Column field="proveedor" header="Proveedor"></Column>
            <!-- <Column field="modelo" header="Modelo Equipo"></Column> -->
            <Column field="ubicacion" :sortable="true" header="Ubicación"></Column>
            <Column field="fechaFinGarantia" :sortable="true" header="Fecha garantía hasta">
              <template #body="body">
                <div class="text-center">
                  <span>{{ formatDate(body.data.fechaFinGarantia, 'DD-MM-YYYY') }}</span>
                </div>
              </template>
            </Column>
            <Column field="ingenieroContrato" header="Asignada a"></Column>
            <Column field="estado" :sortable="true" header="Estado"></Column>
            <Column header="Ficha Equipo">
              <template #body="body">
                <base-button
                  size="sm"
                  type="secondary"
                  icon="pi pi-qrcode"
                  :id="body.data.elementid"
                  v-on:click="viewElementDetails($event)"
                />
              </template>
            </Column>
<!-- 
            <Column header="Acciones">
              <template #body="body">
                <div v-if="body.data.estado == 'pendiente'">
                  <base-button
                    icon="pi pi-envelope"
                    size="sm"
                    type="secondary"
                    :id="body.data.key"
                    v-on:click="openCarta($event)"
                  >Carta garantía
                  </base-button>
                </div>
                <div v-else>
                  <base-button
                    icon="pi pi-check-circle"
                    size="sm"
                    type="secondary"
                    :id="body.data.key"
                    v-on:click="marcarResuelto($event)"
                  >Marcar resuelto
                  </base-button>
                </div>
              </template>
            </Column>
 -->
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <div
    class="screen"
    :hidden="!showModalDetalleSolicitud"
  ></div>
</template>
<script>
import firebase from "firebase";
import Button from "primevue/button";
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import Panel from 'primevue/panel';
import QrcodeVue from 'qrcode.vue';
import SelectButton from 'primevue/selectbutton';
import moment from 'moment';
require("moment/locale/es");
import { getUserKeyAndType, getSolicitudes, stringInject} from "@/model/functions";
import { formatDate } from "@/components/utils";
import { VueEditor } from "vue3-editor";
import VueDocumentEditor from 'vue-document-editor'; //genera este warning: @vue/compiler-sfc] ::v-deep usage as a combinator has been deprecated. Use :deep(<inner-selector>) instead. 
// import VueFileToolbarMenu from 'vue-file-toolbar-menu';

import axios from "axios";
const bearerTokenCarbone =
  "test_eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI1NDU0IiwiYXVkIjoiY2FyYm9uZSIsImV4cCI6MjI3OTk3OTY3NCwiZGF0YSI6eyJpZEFjY291bnQiOjU0NTR9fQ.AC2z39t60d-Pb_hpZQV6zKRnzZZ2B6SjJFS_kp-lEqvjPubPQHUzFJ8M-FklPzkq4hQF8wcW99KswisZgN9DSXkrAEC1p1w3wbROcehRVIaVYs0Re0sjbMEBh0JFVWS08gSsugzDhh7J4GqwgnHVWuQC-Bid_d3bZRY37PSaabpch92-";
  // "eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI1NDU0IiwiYXVkIjoiY2FyYm9uZSIsImV4cCI6MjI3OTk5MzU0MSwiZGF0YSI6eyJpZEFjY291bnQiOjU0NTR9fQ.AdvHniYzyoKwe2p2Ht61mW77mC3ikzHlXY9ol_Pqn3mS5Bc6Z86uwDCTPBwgVM8NDRrxfe8UljY8hKsKz2HQ8S7VAcVlJEfR-jFl2HxisQO6eiRDX7aiYojSR8IXQTGuRd20uQIyQuAlUM1e4o4sUu9LUWsfaviq2scWma0DetKIsxEE";
const templateIdCarbone =
  // "ea049a9ae07334e9508046c03d404964fd129fb9d9e24b23772490ee145b4200";
  "36f8e7e5c320f37da275393c0e40f8d7f8807440428247ac8cac6d8c5ed3315a";

const useCarboneTemplate = false;

export default {
  name: "tables",
  components: {
    Button,
    DataTable,
    Dialog,
    Column,
    InputText,
    Panel,
    QrcodeVue,
    SelectButton,
    VueEditor,
    VueDocumentEditor,
    // VueFileToolbarMenu,
  },

  data() {
    return {
      useremail: "",
      totalitems: 0,
      totalEnFalla: 0,

      numSolPendientes: 0,
      numSolEnProceso: 0,
      numSolResueltas: 0,

      filters: {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'fechaFalla': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'fecha': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'nombreEquipo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'fabricante': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'proveedor': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'ubicacion': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'fechaFinGarantia': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'estado': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
      },

      loading: false,
      solicitudesPendientes: [],
      // solcitudesResueltas: [],

      showModalDetalleSolicitud: false,
      dataSolicitud: {},
      estadoSolicitud: {name: '', code: ''},
      titleModalSolicitud: "Detalle Solicitud",

			estadosSolicitudes: [
				{name: 'pendiente', code: 'pendiente'},
				{name: 'en proceso', code: 'en proceso'},
				{name: 'resuelta', code: 'resuelta'},
			],

      loadingData: false,
      content: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"]
      ],

      userDisplayName: '',

      timestampLastAction: 0,
    };
  },

  computed: {
    contentWrap() {
      return [this.content];
    }
  },

  watch: {
    estadoSolicitud() {
      console.log("on change -- estadoSolicitud ->", this.dataSolicitud);
      if(this.estadoSolicitud.name !== '')
      {
        firebase.database().ref(this.db_ref_solicitudes + "/" + this.dataSolicitud.key).update({ estado: this.estadoSolicitud.code }); //set estado solicitud
        if(this.estadoSolicitud.code == "resuelta")
        {
          const elementid = this.dataSolicitud.elementid;
          firebase.database().ref(this.db_ref_inventario + "/" + elementid).update({ falla:false, fechaFalla: ''});

          let fechaSolucion = Date.now();
          var newPost = firebase.database().ref(this.db_ref_inventario + "/" + elementid + "/historial").push();
          newPost.set({
            fecha: fechaSolucion,
            estado: "Operativo",
            observacion: 'Solicitud de ejecución de garantía ha sido resuelta',
            user: this.userDisplayName,
          });
          
        }
      }
    },
  },

  methods: {

    formatDate,

    isDuplicatedCall()
    {
      //ToDo: resolver tema de llamada doble
      var timestamp = Date.now();
      console.log("deltaT: " + (timestamp - this.timestampLastAction));
      if(timestamp - this.timestampLastAction > 2000)
      {
        this.timestampLastAction = timestamp;
        return false;
      }
      else
      {
        console.log("----- double call exit -----");
        return true;
      }        
    },    

    openSolicitud: function (event) {
      
      let targetId = event.currentTarget.id;
      console.log("openSolicitud -> " + this.db_ref_solicitudes + "/" + targetId);

      if(this.isDuplicatedCall()) return;

      this.showDetalleSolicitud(targetId);
    },

    showDetalleSolicitud(targetId) {
      firebase.database().ref(this.db_ref_solicitudes + "/" + targetId).once("value", snapshot => {
        if(snapshot.exists())
        {
          // console.log("openSolicitud data ->", event.currentTarget.id);
          this.dataSolicitud = { key:snapshot.key, ...snapshot.val()};
          this.estadoSolicitud = {name: this.dataSolicitud.estado, code: this.dataSolicitud.estado};

          this.titleModalSolicitud = "Detalle Solicitud " +  this.dataSolicitud.numSolicitud;
      
          if(this.dataSolicitud.carta)
          {
            this.content = this.dataSolicitud.carta;
          }
          else
          {
            let dataContent = {
                            date: formatDate(Date.now(), "D [de] MMMM [de] YYYY"),
                            proveedor: this.dataSolicitud.proveedor,
                            idContrato: this.dataSolicitud.idContrato,
                            proveedor_1: this.dataSolicitud.proveedor,
                            nombreEquipo: this.dataSolicitud.nombreEquipo,
                            idContrato_1: this.dataSolicitud.idContrato,
                            reporteFalla: this.dataSolicitud.reporteFalla,
                            ingenieroContrato: this.dataSolicitud.ingenieroContrato,
                            mailIngenieroContrato: this.dataSolicitud.mailIngenieroContrato,
                            idContrato_2: this.dataSolicitud.idContrato
                          };

            this.content = stringInject(this.letterTemplate[this.clientName], dataContent);
          }

          this.showModalDetalleSolicitud = true;
          // this.$router.push("/element/" + targetId);
        }
        else{
          console.log("ID de solcitud no existe: %s", targetId);
        }
      });
    },

    viewElementDetails: function (event) {
      let targetId = event.currentTarget.id;
      console.log(targetId); // returns 'foo'
      this.$router.push("/element/" + targetId);
    },

    openCarta(event){
      var targetId = event.currentTarget.id;
      this.$router.push("/doc/" + targetId);
    },

    marcarResuelto(event){
      var targetId = event.currentTarget.id;
      console.log("marcarResuelto", this.db_ref_solicitudes + "/" + targetId);
      firebase.database().ref(this.db_ref_solicitudes + "/" + targetId).update({ estado: "resuelta" });
    },

    print(){
      window.print();
    },

    procesarSolicitud(elementid){
      
      this.loadingData = true;

      firebase.database().ref(this.db_ref_solicitudes + "/" + elementid).update({ estado: 'en proceso', carta: this.content});
      console.log("··········this.content··········");
      console.log(this.content);
      this.loadingData = false;

      if(useCarboneTemplate)
      {
        let carboneData = {content: this.content};
        // this.generateCarboneReport(carboneData, "docx").then(() => {
        this.generateCarboneReport(carboneData, "pdf").then(() => {
          console.log("descarga OK");
          this.loadingData = false;
          this.showModalDetalleSolicitud = false;
        })
        .catch(() => {
          console.log(".catch(...)");
          this.loadingData = false;
          this.showModalDetalleSolicitud = false;
        });
      }
    },

    generateCarboneReport(data, convertTo) {

      return new Promise((resolve, reject) => {
      const requestData = {
          data: data,
          convertTo: convertTo,
      };
      const carboneConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerTokenCarbone,
          "carbone-version": "3",
        },
      };
      const url = "https://render.carbone.io/render/" + templateIdCarbone + "/";
      axios
        .post(url, requestData, carboneConfig)
        .then((response) => {
          console.log(response);
          // noinspection JSUnresolvedVariable
          const urlArchivo =
            "https://render.carbone.io/render/" +
            String(response.data.data.renderId);
          axios
            .get(urlArchivo, { responseType: "blob" })
            .then((responseFile) => {
              const blob = new Blob([responseFile.data], {
                type: "application/pdf",
              });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "CartaGarantia.pdf";
              link.click();
              URL.revokeObjectURL(link.href);
            })
            .finally(() => {
                resolve();
                // this.loadingData = false;
                // this.showModalDetalleSolicitud = false;
            });
        })
        .catch((err) => {
          console.log("Error en primer request", err);
            reject();
            // this.loadingData = false;
            // this.showModalDetalleSolicitud = false;
          });
        });
    },
  },

  mounted() {

    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.useremail = user.email;
        console.log("USER SIGNED");

        getUserKeyAndType(this.db_ref_users, user.email, (userKey, usertype, data) => {
          console.log("getUserType", userKey, usertype, data);

          if((usertype != 1) && (usertype != 3)) //solo admins y resolutores pueden resolver solicitudes
          {
            this.$router.push('/tables');
            return;
          }

          this.userDisplayName = data.nombres + " " + data.apellidos;

          getSolicitudes(this.db_ref_solicitudes, (listSolicitudes) => { 
            this.totalEnFalla = 0;
            this.totalitems = 0;
            this.numSolPendientes = 0;
            this.numSolEnProceso = 0;
            this.numSolResueltas = 0;

            this.solicitudesPendientes = listSolicitudes; 

            this.solicitudesPendientes.forEach(ArrayDB => {
              if(ArrayDB.falla) this.totalEnFalla++;

              if(ArrayDB.estado === 'pendiente') this.numSolPendientes++;
              if(ArrayDB.estado === 'en proceso') this.numSolEnProceso++;
              if(ArrayDB.estado === 'resuelta') this.numSolResueltas++;

              this.totalitems++;
            });
            console.log("solicitudesPendientes", this.solicitudesPendientes);

            if(this.$route.params.elementid)
            {
              this.showDetalleSolicitud(this.$route.params.elementid);
            }
          });
        });
      } else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        if(this.$route.params.elementid)
        {
          this.$router.push({ name: "login", params: { path: "Detalle solicitud", elementid: this.$route.params.elementid } });
        }
        else
        {
          this.$router.push("/login");
        }
      }
    });
  },

  created() {},
};
</script>
<style>

.screen {
  background-color: rgba(64, 68, 105,0.5);
  filter: (5px);
  pointer-events: none;
  position: fixed;   top: 0px;   left: 0px;
  height: 100vh;
  width: 100vw;
}

</style>
