<template>
  <div class="main-content bg-default"> <!-- Estilo Transelec & Codelco -->
  <!-- <div class="main-content bg-default-x" style="background-color: silver;">  --> <!-- Estilo BHP -->
    <!-- Navbar -->
    <base-nav
      class="navbar-top navbar-horizontal navbar-dark"
      containerClasses="px-4 container"
      expand
    >
      <!-- <router-link slot="brand" class="navbar-brand" to="/">
                <img src="img/brand/white.png"/>
            </router-link> -->

      <template v-slot="{ closeMenu }">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <!--
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img src="img/brand/green.png" />
              </router-link>
            </div>
             -->
            <div class="col-6 collapse-close">
              <button
                type="button"
                @click="closeMenu"
                class="navbar-toggler"
                aria-label="Toggle sidenav"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!-- Navbar items -->
        <!--         
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link nav-link-icon" to="/">
              <i class="ni ni-planet"></i>
              <span class="nav-link-inner--text">Dashboard</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link nav-link-icon" to="/login">
              <i class="ni ni-key-25"></i>
              <span class="nav-link-inner--text">Login</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link nav-link-icon" to="/profile">
              <i class="ni ni-single-02"></i>
              <span class="nav-link-inner--text">Profile</span>
            </router-link>
          </li>
        </ul>
        -->
      </template>

    </base-nav>
    <!-- Header -->
    <!-- <div class="header bg-gradient-info py-7 py-lg-8"> -->
    <!-- <div class="header bg-gradient-info py-7 py-lg-8"> --> <!-- Estilo Transelec -->
    <div class="header py-7 py-lg-7" style="background-color:#0098AA;">  <!-- Estilo Codelco -->
    <!-- <div class="header py-7 py-lg-7" style="background-color:#CD6026;"> -->  <!-- Estilo BHP -->
      <div class="container">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6">
              <h1 class="text-white">Bienvenido</h1>
              <p class="text-lead text-white">
                Pagina de acceso a plataforma
              </p>
              <img :src="this.loginImage" class="navbar-brand-img" style="width:380px" alt="..." />
            </div>
          </div>
        </div>
      </div>
     <div class="separator separator-bottom separator-skew zindex-100" style="background-color:#0072B1;"> <!-- Estilo Transelec & Codelco -->
      <!-- <div class="separator separator-bottom separator-skew zindex-100" style="background-color:#CD6026;"> -->  <!-- Estilo BHP -->
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon> <!-- Estilo Transelec & Codelco -->
          <!-- <polygon class="fill-default" style="fill: whitesmoke;"  points="2560 0 2560 100 0 100"></polygon> --> <!-- Estilo BHP -->
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <router-view></router-view>
    </div>
    <footer class="py-5">
      <div class="container">
        <div v-if="this.clientPartner" class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-left text-muted">
              <a
                :href="this.clientPartnerURL"
                class="font-weight-bold ml-1"
                style="color:#0098AA;"
                target="_blank"
                >{{this.clientPartner}}</a
              >
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-left text-muted">
              &copy; {{ year }}
              <a
                href="https://www.pcvuesolutions.com/lat"
                class="font-weight-bold ml-1"
                style="color:#0098AA;"
                target="_blank"
                >PcVue</a
              >
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "auth-layout",
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
};
</script>
<style></style>
