/*!

=========================================================
* Vue Argon Dashboard - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import Vue from "vue";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
// import "element-plus/lib/theme-chalk/index.css";
import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage

//import Vue from 'vue';
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import Button from 'primevue/button';
import ToastService from "primevue/toastservice";
import VueApexCharts from "vue3-apexcharts";

const appInstance = createApp(App);

/*  Transelec */
// appInstance.config.globalProperties.clientName = "Transelec"; 
// appInstance.config.globalProperties.loginImage = "img/brand/transelec/logo-transelec-blue-transp.png";
// appInstance.config.globalProperties.sideBarImage = "img/brand/transelec/transelec_logo.png";
// appInstance.config.globalProperties.clientPartner = "";
// appInstance.config.globalProperties.clientPartnerURL = "";

// appInstance.config.globalProperties.db_ref_solicitudes = "solicitudes"; 
// appInstance.config.globalProperties.db_ref_inventario = "inventario/Central"; 
// appInstance.config.globalProperties.db_ref_users = "usuarios"; 


/*  BHP */
// appInstance.config.globalProperties.urlBase = "https://e-garant-bhp.pcvuelat.com";
// appInstance.config.globalProperties.urlBase = "https://e-garant-clientes.web.app";
// appInstance.config.globalProperties.urlBase = "https://e-garant-bhp.web.app";

appInstance.config.globalProperties.urlBase = "https://e-garant.pcvuelat.com";
// urlBase: "https://transelec.pcvuelat.com", 
// urlBase: "http://trelec-ec4fb.web.app", 
// urlBase: "http://localhost:8080", 


// appInstance.config.globalProperties.clientName = "BHP";
// appInstance.config.globalProperties.loginImage = "img/brand/bhp/logo_white.png";
// // appInstance.config.globalProperties.loginPrimaryColor = "#cd6026";
// appInstance.config.globalProperties.loginPrimaryColor = "#0098AA";

// appInstance.config.globalProperties.sideBarImage = "img/brand/bhp/logo_sidebar.jpeg";
// appInstance.config.globalProperties.clientPartner = "";
// appInstance.config.globalProperties.clientPartnerURL = "";

// appInstance.config.globalProperties.db_ref_solicitudes = "solicitudes2/Spence";
// appInstance.config.globalProperties.db_ref_inventario = "inventario/Spence";
// // appInstance.config.globalProperties.db_ref_solicitudes = "solicitudes2/Escondida";
// // appInstance.config.globalProperties.db_ref_inventario = "inventario/Escondida";

// appInstance.config.globalProperties.db_ref_users = "usuarios2/BHP";
// appInstance.config.globalProperties.imageX = 'img/brand/codelco/chuqui.jpg';


appInstance.config.globalProperties.backgroundImages = {
    dashboard: 'img/brand/codelco/chuqui.jpg',
    element: 'img/brand/codelco/tunel_codelco.png',
    inboxSolicitudes: 'img/brand/codelco/el_teniente_tunel.jpg',
    tables: 'img/brand/codelco/camiones2.jpg',
    usuarios: 'img/brand/codelco/chuqui_users.png',
};

/*  Aquanima - Codelco */
// appInstance.config.globalProperties.clientName = "Codelco";
// appInstance.config.globalProperties.loginImage = "img/brand/codelco/logo_cyan.jpg";
// appInstance.config.globalProperties.loginPrimaryColor = "#0098AA";
// appInstance.config.globalProperties.sideBarImage = "img/brand/codelco/logo_sidebar.png";
// appInstance.config.globalProperties.clientPartner = "Aquanima";
// appInstance.config.globalProperties.clientPartnerURL = "https://www.aquanima.com";

// appInstance.config.globalProperties.db_ref_solicitudes = "solicitudes2/Teniente"; 
// appInstance.config.globalProperties.db_ref_inventario = "inventario/Teniente"; 
// appInstance.config.globalProperties.db_ref_users = "usuarios2/Aquanima"; 

/*  Celplan - Codelco */
appInstance.config.globalProperties.clientName = "Codelco";
appInstance.config.globalProperties.loginImage = "img/brand/codelco/logo_cyan.jpg";
appInstance.config.globalProperties.loginPrimaryColor = "#0098AA";
appInstance.config.globalProperties.sideBarImage = "img/brand/codelco/logo_sidebar.png";
appInstance.config.globalProperties.clientPartner = "";
appInstance.config.globalProperties.clientPartnerURL = "";

appInstance.config.globalProperties.db_ref_solicitudes = "solicitudes2/Teniente"; 
appInstance.config.globalProperties.db_ref_inventario = "inventario/Celplan"; 
appInstance.config.globalProperties.db_ref_users = "usuarios2/Aquanima"; 


/*  BHP */
appInstance.config.globalProperties.columnsDataTableElements = {
    default: false,
    ID: false,
    TAG: true,
    numeroSerie: false,
    nombreEquipo: true,
    contrato: true,
    proyecto: true,
    fabricante: false,
    proveedor: true,
    modelo: false,
    ubicacion: true,
    areaResponsable: false,
    comentarios: false,
    idContrato: true,
    anoFabricacion: false,
    fechaPES: false,
    mesesGarantia: false,
    fechaFinGarantia: true,
    diasFinGarantia: true,
    categoria: false,
    falla: true,
};

/*  BHP */
// appInstance.config.globalProperties.sectionsElementView = {
//     estadoGarantia: true,
//     detallesEquipo: true,
//     informacionAdicional: true,
//     historial: true,
//     fichaProveedor: true,
//     fichaTecnica: false,
// }

/*  Celplan - Codelco */
appInstance.config.globalProperties.sectionsElementView = {
    estadoGarantia: true,
    detallesEquipo: false,
    informacionAdicional: false,
    historial: false,
    fichaProveedor: true,
    fichaTecnica: false,
}


appInstance.config.globalProperties.letterTemplate = {
    BHP: '' //clientName
        + '<p class="ql-align-right"><img src="img/assets/bhp/headerCarta.png" alt="" height="137" ></p>'
        + '<p class="ql-align-justify"><span style="background-color: transparent; color: rgb(255, 0, 0);">XXXXXXX-X</span><span style="background-color: transparent; color: rgb(0, 0, 0);">-CR-T-00</span><span style="background-color: transparent; color: rgb(255, 0, 0);">XX&nbsp;</span></p><p>'
        + '<span style="background-color: transparent; color: rgb(0, 0, 0);">Santiago, </span>#date#</p>'
        + '<p class="ql-align-right">Requiere Respuesta:&nbsp;Si: <u>X</u> / No: __ </p>'
        + '<p class="ql-align-justify">Señores</p><p>#proveedor#</p><p><br></p>'
        + '<p><strong>Atención</strong>: 	Sr. XXX XXX</p><p>'
        + '<strong>Contrato</strong>:	#idContrato#</p>'
        + '<p><strong>Referencia</strong>:	“XXX”</p>'
        + '<p><strong>Asunto</strong>:	Notificación de activación de garantía de equipo</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Estimados señores:</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Por medio de la presente se le notifica a #proveedor_1# de que el equipo #nombreEquipo#, suministro comprado mediante contrato #idContrato_1#, ha presentado la siguiente falla: #reporteFalla#, toda vez que el equipo se encuentra con la garantía en periodo vigente.&nbsp;</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Solicitamos gestionar la solución propuesta por ustedes y enviarla al Ingeniero de Contratos #ingenieroContrato# al correo #mailIngenieroContrato# en un plazo máximo de 5 días hábiles.</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Sin otro particular, saluda atentamente a usted,</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify"><strong></strong></p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Gerente de Proyecto</p>'
        + '<p class="ql-align-justify"><strong>Contrato #idContrato_2#</strong></p><p><br></p>'
        // + '<p><img src="img/footerCarta.png" height="120" width="754.3727264404297"></p>';
        + '<p></p>',

    Codelco: '' //clientName
        // + '<p class="ql-align-right"><img src="img/headerCarta.png" alt="" height="137" width="651"></p>'
        + '<p class="ql-align-justify"><span style="background-color: transparent; color: rgb(255, 0, 0);">XXXXXXX-X</span><span style="background-color: transparent; color: rgb(0, 0, 0);">-CR-T-00</span><span style="background-color: transparent; color: rgb(255, 0, 0);">XX&nbsp;</span></p><p>'
        + '<span style="background-color: transparent; color: rgb(0, 0, 0);">Santiago, </span>#date#</p>'
        + '<p class="ql-align-right">Requiere Respuesta:&nbsp;Si: <u>X</u> / No: __ </p>'
        + '<p class="ql-align-justify">Señores</p><p>#proveedor#</p><p><br></p>'
        + '<p><strong>Atención</strong>: 	Sr. XXX XXX</p><p>'
        + '<strong>Contrato</strong>:	#idContrato#</p>'
        + '<p><strong>Referencia</strong>:	“XXX”</p>'
        + '<p><strong>Asunto</strong>:	Notificación de activación de garantía de equipo</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Estimados señores:</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Por medio de la presente se le notifica a #proveedor_1# de que el equipo #nombreEquipo#, suministro comprado mediante contrato #idContrato_1#, ha presentado la siguiente falla: #reporteFalla#, toda vez que el equipo se encuentra con la garantía en periodo vigente.&nbsp;</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Solicitamos gestionar la solución propuesta por ustedes y enviarla al Ingeniero de Contratos #ingenieroContrato# al correo #mailIngenieroContrato# en un plazo máximo de 5 días hábiles.</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Sin otro particular, saluda atentamente a usted,</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify"><strong></strong></p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Gerente de Proyecto</p>'
        + '<p class="ql-align-justify"><strong>Contrato #idContrato_2#</strong></p><p><br></p>'
        // + '<p><img src="img/footerCarta.png" height="120" width="754.3727264404297"></p>';
        + '<p></p>',

    Transelec: '' //clientName
        + '<p class="ql-align-right"><img src="img/assets/transelec/headerCarta.png" alt="TRANSELEC HOJA CARTA (06 10 2014)-03.png" height="137" width="651"></p>'
        + '<p class="ql-align-justify"><span style="background-color: transparent; color: rgb(255, 0, 0);">XXXXXXX-X</span><span style="background-color: transparent; color: rgb(0, 0, 0);">-CR-T-00</span><span style="background-color: transparent; color: rgb(255, 0, 0);">XX&nbsp;</span></p><p>'
        + '<span style="background-color: transparent; color: rgb(0, 0, 0);">Santiago, </span>#date#</p>'
        + '<p class="ql-align-right">Requiere Respuesta:&nbsp;Si: <u>X</u> / No: __ </p>'
        + '<p class="ql-align-justify">Señores</p><p>#proveedor#</p><p><br></p>'
        + '<p><strong>Atención</strong>: 	Sr. XXX XXX</p><p>'
        + '<strong>Contrato</strong>:	#idContrato#</p>'
        + '<p><strong>Referencia</strong>:	“XXX”</p>'
        + '<p><strong>Asunto</strong>:	Notificación de activación de garantía de equipo</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Estimados señores:</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Por medio de la presente se le notifica a #proveedor_1# de que el equipo #nombreEquipo#, suministro comprado mediante contrato #idContrato_1#, ha presentado la siguiente falla #reporteFalla#, toda vez que el equipo se encuentra con la garantía en periodo vigente.&nbsp;</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Solicitamos gestionar la solución propuesta por ustedes y enviarla al Ingeniero de Contratos #ingenieroContrato# al correo #mailIngenieroContrato# en un plazo máximo de 5 días hábiles.</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Sin otro particular, saluda atentamente a usted,</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify"><strong>TRANSELEC S.A.</strong></p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Gerente de Proyecto</p>'
        + '<p class="ql-align-justify"><strong>Contrato #idContrato_2#</strong></p><p><br></p>'
        + '<p><img src="img/assets/transelec/footerCarta.png" height="120" width="754.3727264404297"></p>',

}

appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(PrimeVue);
appInstance.use(DataTable);
appInstance.use(InputText);
appInstance.use(Column);
appInstance.use(Button);
appInstance.use(ToastService);
appInstance.use(VueApexCharts);
appInstance.mount("#app");

var firebaseConfig = {
    apiKey: "AIzaSyAcqYU4VUa3UT0zuKzSjk4oazwotrmXp7c",
    authDomain: "trelec-ec4fb.firebaseapp.com",
    databaseURL: "https://trelec-ec4fb-default-rtdb.firebaseio.com",
    projectId: "trelec-ec4fb",
    storageBucket: "trelec-ec4fb.appspot.com",
    messagingSenderId: "393977052780",
    appId: "1:393977052780:web:3c03ad67ce0a5a0873e249",
    measurementId: "G-XMH8W5MPNQ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//Vue.config.productionTip = false;

/* new Vue({
    router,
    render: h => h(App),
}).$mount('#app'); */