<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar v-if="useremail"
      :background-color="sidebarBackground"
      :logo="this.sideBarImage"
      short-title="PcVue"
      title="PcVue"
    >
      <template v-slot:links>
        <sidebar-item v-if="(usertype == 1) || (usertype == 3) || (usertype == 4)"
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-red',
            path: '/dashboard',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Listado Equipos',
            icon: 'ni ni-collection text-orange',
            path: '/tables',
          }"
        />
        <sidebar-item v-if="(usertype == 1) || (usertype == 3)"
          :link="{
            name: 'Bandeja Entrada',
            icon: 'pi pi-inbox text-orange',
            path: '/solicitudes',
          }"
        />
        <sidebar-item v-if="(usertype == 1)"
          :link="{
            name: 'Administracion Usuarios',
            icon: 'pi pi-users text-orange',
            path: '/usuarios',
          }"
        />
<!--         
        <sidebar-item
          :link="{
            name: 'Contactos',
            icon: 'pi pi-book text-orange',
            path: '/tables',
          }"
        />
 -->
<!-- 
        <sidebar-item
          :link="{
            name: 'Login',
            icon: 'ni ni-key-25 text-info',
            path: '/login',
          }"
        />
 -->
        <sidebar-item
          :link="{
            name: 'Logout',
            icon: 'ni ni-lock-circle-open text-info',
            path: '/Logout',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase";
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { getUserKeyAndType } from "@/model/functions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      useremail: null,
      usertype: null,
      sidebarBackground: "blue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },

  created() {
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("USER SIGNED SideBar");
        this.useremail = user.email;
        getUserKeyAndType(this.db_ref_users, user.email, (userKey, usertype, data) => {
          this.usertype = usertype;
        });
      } else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        // this.$router.push("/login");
      }
    });
  },
};
</script>
<style lang="scss"></style>
