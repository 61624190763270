<template>
  <div>
<!--
    <base-header
      style="background-size: 100% 500px;background-image:linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.2)), url('img/aisladores.jpg');"     
      class="pb-6 pb-8 pt-5 pt-md-8">
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    </base-header>
 -->

<!-- Transelec
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        max-height: 150px;
        background-image: url(img/layout/transelec/aisladores.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
 -->

    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        max-height: 150px;
        background-image: url(img/layout/codelco/tunel_codelco.png);
        background-size: cover;
        background-position: center center;
      "
    >
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    </base-header>

    <Toast position="bottom-right"/>
    <Dialog
      header="Reportar falla equipo"
      v-model:visible="showModalReportarFalla"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"
    >
      <div class="container">
        <div class="row my-3">
          <b class="my-auto">Describa la falla o defecto a reportar del equipo:</b>
        </div>
        <div class="row my-3">
          <div class="col-12">
            <div class="p-inputgroup">
              <!-- <span class="p-inputgroup-addon"><i class="pi pi-comments" /></span> -->
              <Textarea
                v-model="observacion"
                class="w-100"
              />
              </div>
          </div>
        </div>
        <Button label="Generar solicitud ejecución garantía" class="p-button-raised p-button-default" @click="reportarFalla(observacion)"/>
      </div>
    </Dialog>

    <Dialog
      header="Agregar Comentario"
      v-model:visible="showModalAgregarComentario"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"
    >
      <div class="container">
        <div class="row my-3">
          <b class="my-auto">Ingrese comentario respecto al equipo:</b>
        </div>
        <div class="row my-3">
          <div class="col-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="pi pi-comments" /></span>
              <Textarea
                v-model="observacion"
                class="fullwidth"
              />
            </div>
          </div>
        </div>
        <Button label="Agregar Comentario" class="p-button-raised p-button-default" @click="agregarComentario(observacion, 'INFO')"/>
      </div>
    </Dialog>

    <Dialog
    header="Subir Garantías"
    v-model:visible="showModalUploadFiles"
    :breakpoints="{'960px': '90vw', '640px': '100vw'} " :style="{width: '75vw'}">
    <ModalCargaArchivos categoria="garantia" :idEquipo="this.elementid"/>
    </Dialog>

    <Dialog
    header="Subir Fotos de Equipo"
    v-model:visible="showModalUploadPhotos"
    :breakpoints="{'960px': '90vw', '640px': '100vw'} " :style="{width: '75vw'}">
    <ModalCargaArchivos categoria="fotoEquipo" :idEquipo="this.elementid"/>
    </Dialog>

    <Dialog
    header="Subir Manuales Técnicos"
    v-model:visible="showModalUploadManuales"
    :breakpoints="{'960px': '90vw', '640px': '100vw'} " :style="{width: '75vw'}">
    <ModalCargaArchivos categoria="manuales" :idEquipo="this.elementid"/>
    </Dialog>
    <Dialog
    header="Subir Documentos Administrativos"
    v-model:visible="showModalUploadDocs"
    :breakpoints="{'960px': '90vw', '640px': '100vw'} " :style="{width: '75vw'}">
    <ModalCargaArchivos categoria="docs" :idEquipo="this.elementid"/>
    </Dialog>

    <Dialog
      header="Archivos Adjuntados"
      v-model:visible="showModalDetalleArchivos"
      :breakpoints="{'960px': '90vw', '640px': '100vw'}" :style="{width: '75vw'}"
    >
      <DataTable
        class="p-datatable-customers"
        :value="listUploadedFiles"
        :paginator="true"
        :row-selectable="true"
        :rows="10"
        :rowsPerPageOptions="[10,25,50]"
        responsiveLayout="scroll"
        rowStyleClass="#{listUploadedFiles.Evaluacion le 90 ? 'colored' : null}"
      >
        <Column field="NombreArchivo" header="Archivo"></Column>
        <Column field="categoria" header="Tipo Documento"></Column>
        <Column field="FechaCarga" header="Fecha Carga">
          <template #body="body">
            {{ formatDate(body.data.FechaCarga, 'DD-MM-YYYY') }}
          </template>
        </Column>
        <Column  header="Descarga">
        <template #body="body" >
            <base-button
              v-model="url"
              icon="pi pi-cloud-download"
              size="sm"
              type="secondary"              
              v-on:click="downloadFile(body, $event)"
            />
          </template>
        </Column>
      </DataTable>
    </Dialog>

    <div v-if="loading == false" class="container-fluid mt--7">
      <div v-if="notFound" class="row" >
        <div class="col-xl-7 col-lg-4">
          <div class="row w-100" >
            <div class="colum w-100">
              <stats-card
                title="Nombre del Equipo"
                type="gradient-orange"
                sub-title="Equipo no encontrado"
                icon="ni ni-notification-70"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <!-- <h5 class="p-m-0">Equipo no encontrado</h5> -->
                  <br/><br/><br/><br/>
                </template>
              </stats-card>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row" >
        <div class="col-xl-7 col-lg-4">
          <div class="row w-100" >
            <div class="colum w-100">
              <stats-card
                title="Nombre del Equipo"
                type="gradient-orange"
                v-bind:sub-title="datosEquipo.nombreEquipo"
                icon="ni ni-bold-left"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
    <!-- 
                  <h5>Equipo</h5>
                  <div class="p-text-capitalize">{{Equipment}}</div>
                  <h5>PO</h5>
                  <div class="p-text-capitalize">{{POrder}}</div>
                  <h5>Supplier</h5>
                  <div class="p-text-capitalize">{{Supplier}}</div>
                  <h5>Spare type</h5>
                  <div class="p-text-capitalize">{{SpareType}}</div>
                  <h5>Part number</h5>
                  <div class="p-text-capitalize">{{PartNumber}}</div>
    -->
                <!-- "Número de serie" -->
                <!-- "Nombre del Equipo" -->
                <!-- "Descripción del Equipo" -->
                <!-- "Fabricante" -->
                <!-- "Proveedor" -->
                <!-- "Modelo Equipo" -->
                <!-- "Ubicación" -->
                <!-- "Área Responsable" -->
                <!-- "Comentarios (tension kV)" -->
                <!-- "Identificador contrato" -->
                <!-- "Año fabricacion" -->
                <!-- "Fecha de PES" -->
                <!-- "Meses" -->
                <!-- "Fecha garantía hasta" -->
                <!-- "Expiración Garantía" -->
                <!-- "Fotos Equipo" -->
                <!-- "Manuales Técnicos" -->
                <!-- "Documentos administrativos" -->
                <!-- "Categoría Equipo" -->
                <!-- "Criticidad" -->
    
                  <Message v-if="datosEquipo.falla" severity="error" :closable="false">Equipo reportado en falla el {{ formatDate(datosEquipo.fechaFalla, 'DD-MM-YYYY') }}</Message>
                  <div class="row">
                    <div class="col-auto">
                      <table >
                        <tbody>
                          <!-- <tr><td><b>Identificador {{ this.clientName }}</b></td><td>{{datosEquipo.ID}}</td></tr> -->
                          <!-- <tr><td style="padding-right:10px"><b>Descriptor Equipo / Codigo SAP</b></td>{{datosEquipo.TAG}}<td></td></tr> -->
                          <tr><td style="padding-right:10px"><b>TAG Equipo</b></td>{{datosEquipo.TAG ?? "Sin informacion" }}<td></td></tr>

                          <tr><td style="padding-right:10px"><b>Contrato</b></td><td>{{datosEquipo.contrato}}</td></tr>
                          <tr><td style="padding-right:10px"><b>Nº Contrato</b></td><td>{{datosEquipo.idContrato}}</td></tr>

                          <!-- <tr><td><b>Número de serie</b></td><td>{{datosEquipo.numeroSerie}}</td></tr> -->
                          <!-- <tr><td><b>Nombre del Equipo</b></td><td>{{datosEquipo.nombreEquipo}}</td></tr> -->

                          <!-- <tr><td><b>Descripción del Equipo</b></td><td>{{datosEquipo.descripcion}}</td></tr> -->

                          <!-- <tr><td><b>Fabricante</b></td><td>{{datosEquipo.fabricante}}</td></tr> -->
                          <!-- <tr><td><b>Proveedor</b></td><td>{{datosEquipo.proveedor}}</td></tr> -->
                          <!-- <tr><td><b>Modelo Equipo</b></td><td>{{datosEquipo.modelo}}</td></tr> -->
                          <tr><td style="padding-right:10px"><b>Ubicación</b></td><td>{{datosEquipo.ubicacion}}</td></tr>
                          <tr v-if="datosEquipo.areaResponsable"><td style="padding-right:10px"><b>Área Responsable</b></td><td>{{datosEquipo.areaResponsable}}</td></tr>
                          <tr v-if="datosEquipo.ADC"><td style="padding-right:10px"><b>ADC</b></td><td>{{datosEquipo.ADC}}</td></tr>
                          <!-- <tr><td><b>Comentarios (tension kV)</b></td><td>{{datosEquipo.comentarios}}</td></tr> -->

                          <!-- <tr><td><b>Identificador contrato</b></td><td>{{datosEquipo.idContrato}}</td></tr> -->

                          <!-- <tr><td><b>Año fabricacion</b></td><td>{{datosEquipo.anoFabricacion}}</td></tr> -->
                          <tr v-if="datosEquipo.fechaPES"><td><b>Fecha de PES</b></td><td>{{datosEquipo.fechaPES}}</td></tr>
                          <!-- <tr><td><b>Meses</b></td><td>{{datosEquipo.mesesGarantia}}</td></tr> -->
                          <!-- <tr><td><b>Fecha garantía hasta</b></td><td>{{ datosEquipo.fechaFinGarantia }}</td></tr> -->
                          <!-- <tr><td><b>Expiración Garantía</b></td><td>{{datosEquipo.expiracionGarantia}}</td></tr> -->
                          <!-- <tr><td><b>Fotos Equipo</b></td><td>{{datosEquipo.listImagenes}}</td></tr> -->
                          <!-- <tr><td><b>Manuales Técnicos</b></td><td>
                            <base-button
                              v-model="LinkArchivo"
                              icon="pi pi-paperclip"
                              size="sm"
                              type="secondary"
                              :id="JSON.stringify(datosEquipo.listAdjuntos)"
                              @click="showUploadFiles('', $event)"></base-button>
                          </td></tr> -->
                          <!-- <tr><td><b>Documentos administrativos</b></td><td>{{datosEquipo.listDocumentos}}</td></tr> -->
                          <tr v-if="datosEquipo.categoria"><td style="padding-right:10px"><b>Categoría Equipo </b></td><td>{{datosEquipo.categoria}}</td></tr>
                          <tr v-if="datosEquipo.criticidad"><td style="padding-right:10px"><b>Criticidad</b></td><td>{{datosEquipo.criticidad}}</td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </template>
              </stats-card>
            </div>
          </div>
          <div class="row w-100" >
            <div class="colum w-100">
              <stats-card
                v-if="this.sectionsElementView.estadoGarantia"
                title="Estado garantía"
                type="gradient-orange"
                sub-title="Vigente"
                icon="ni ni-time-alarm"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <div class="row">
                    <div class="col-auto">
                      <table >
                        <tbody>
                          <tr>
                              <td><b>Documentos Garantía</b></td><td>
                              <base-button
                                v-model="LinkArchivo"
                                icon="pi pi-paperclip"
                                size="sm"
                                type="secondary"
                                :id="JSON.stringify(datosEquipo.listAdjuntos)"
                                @click="showUploadFiles('garantia', $event)"></base-button>

                              <base-button v-if="(usertype == 1) || (usertype == 4)"
                                v-model="uploadFile"
                                icon="pi pi-cloud-upload"
                                size="sm"
                                type="primary"
                                @click="uploadFiles($event)"></base-button>
                              </td></tr>
                            <tr><td style="padding-right:10px"><b>Fecha expiración garantía</b></td><td>{{formatDate(datosEquipo.fechaFinGarantia, 'DD-MM-YYYY')}}</td></tr>
                          <tr v-if="datosEquipo.mesesGarantia"><td style="padding-right:10px"><b>Meses garantía</b></td><td>{{datosEquipo.mesesGarantia}}</td></tr>
                          <tr><td style="padding-right:10px"><b>Fabricante</b></td><td v-if="datosEquipo.mesesGarantia">{{datosEquipo.fabricante}}</td><td v-else>Sin información</td></tr>
                          <tr><td style="padding-right:10px"><b>Proveedor</b></td><td>{{datosEquipo.proveedor}}</td></tr>
                          <tr><td style="padding-right:10px"><b>Fecha Puesta En Servicio (PES)</b></td><td v-if="datosEquipo.fechaPES">{{formatDate(datosEquipo.fechaPES, 'DD-MM-YYYY')}}</td><td v-else>Sin información</td></tr>
                        </tbody>
                      </table>
                      <div v-if="datosEquipo.falla">
                        <div v-if="useremail"  class="my-3">
                          <!-- <Button label="Informar solución de falla" class="p-button-raised p-button-success" @click="showModalAgregarComentario = true"/> -->
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="useremail"  class="my-3">
                          <Button label="Reportar falla equipo" class="p-button-raised p-button-danger" @click="showModalReportarFalla = true"/>
                        </div>
                        <div v-else class="my-3">
                          <Button label="¿reportar equipo en falla?" class="p-button-raised p-button-info" @click="login"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </stats-card>
            </div>

            <div class="colum w-100">
              <stats-card
                v-if="this.sectionsElementView.detallesEquipo"
                title="Detalles Equipo"
                type="gradient-orange"
                sub-title=""
                icon="ni ni-settings-gear-65"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <div class="row">
                    <div class="col-auto">
                      <table >
                        <tbody>
                          <tr><td style="padding-right:10px"><b>Nombre del Equipo</b></td><td>{{datosEquipo.nombreEquipo}}</td></tr>
                          <tr><td style="padding-right:10px"><b>Descripción del Equipo</b></td><td>{{datosEquipo.descripcion}}</td></tr>
                          <tr><td style="padding-right:10px"><b>Número de serie</b></td><td v-if="datosEquipo.numeroSerie">{{datosEquipo.numeroSerie}}</td><td v-else>Sin información</td></tr>
                          <tr><td style="padding-right:10px"><b>Modelo Equipo</b></td><td v-if="datosEquipo.modelo">{{datosEquipo.modelo}}</td><td v-else>Sin información</td></tr>
                          <tr><td style="padding-right:10px"><b>Fabricante</b></td><td v-if="datosEquipo.fabricante">{{datosEquipo.fabricante}}</td><td v-else>Sin información</td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
              </stats-card>
            </div>
            
            <div class="colum w-100">
              <stats-card
                v-if="this.sectionsElementView.informacionAdicional"
                title="Informacion adicional"
                type="gradient-red"
                sub-title=""
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <div class="row">
                    <div class="col-auto">
                      <table >
                        <tbody>
                          <!-- <tr><td><b>Fotos Equipo</b></td><td>{{datosEquipo.listImagenes}} -->
                          <tr><td><b>Fotos Equipo</b></td><td>
                            <base-button
                              v-model="LinkArchivo"
                              icon="pi pi-paperclip"
                              size="sm"
                              type="secondary"
                              :id="JSON.stringify(datosEquipo.listImagenes)"
                              @click="showUploadFiles('fotoEquipo', $event)"></base-button>
                              <base-button v-if="(usertype == 1) || (usertype == 4)"
                                v-model="uploadFile"
                                icon="pi pi-cloud-upload"
                                size="sm"
                                type="primary"
                                @click="uploadPhotos($event)"></base-button>
                            </td></tr>
                          <tr><td><b>Manuales Técnicos</b></td><td>
                            <base-button
                              v-model="LinkArchivo"
                              icon="pi pi-paperclip"
                              size="sm"
                              type="secondary"
                              :id="JSON.stringify(datosEquipo.listAdjuntos)"
                              @click="showUploadFiles('manuales', $event)"></base-button>
                              <base-button v-if="(usertype == 1) || (usertype == 4)"
                                v-model="uploadFile"
                                icon="pi pi-cloud-upload"
                                size="sm"
                                type="primary"
                                @click="uploadManuales($event)"></base-button>
                          </td></tr>
                          <tr><td><b>Documentos administrativos</b></td><td>
                            <base-button
                              v-model="LinkArchivo"
                              icon="pi pi-paperclip"
                              size="sm"
                              type="secondary"
                              :id="JSON.stringify(datosEquipo.listDocumentos)"
                              @click="showUploadFiles('docs', $event)"></base-button>
                              <base-button v-if="(usertype == 1) || (usertype == 4)"
                                v-model="uploadFile"
                                icon="pi pi-cloud-upload"
                                size="sm"
                                type="primary"
                                @click="uploadDocs($event)"></base-button>
                            </td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </template>
              </stats-card>
            </div>
          </div>

          <!-- 
          <div class="row" >
            <stats-card
              title="Informacion adicional"
              type="gradient-red"
              sub-title=""
              class="mb-4 mb-xl-3"
            >
              <template v-slot:footer>
                <div class="row">
                  <div class="col-auto">
                    <table >
                      <tbody>
                        <tr><td><b>Fotos Equipo</b></td><td>{{datosEquipo.listImagenes}}
                           <base-button
                            v-model="LinkArchivo"
                            icon="pi pi-paperclip"
                            size="sm"
                            type="secondary"
                            :id="JSON.stringify(datosEquipo.listImagenes)"
                            @click="showUploadFiles('', $event)"></base-button>
                        </td></tr>
                        <tr><td><b>Manuales Técnicos</b></td><td>
                          <base-button
                            v-model="LinkArchivo"
                            icon="pi pi-paperclip"
                            size="sm"
                            type="secondary"
                            :id="JSON.stringify(datosEquipo.listAdjuntos)"
                            @click="showUploadFiles('', $event)"></base-button>
                        </td></tr>
                        <tr><td><b>Documentos administrativos</b></td><td>
                           <base-button
                            v-model="LinkArchivo"
                            icon="pi pi-paperclip"
                            size="sm"
                            type="secondary"
                            :id="JSON.stringify(datosEquipo.listDocumentos)"
                            @click="showUploadFiles('', $event)"></base-button>
                          </td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </template>
            </stats-card>
          </div>
          -->

          <!-- 
          <h5>Historial</h5>
          <Fieldset legend="Observaciones" :toggleable="true" :collapsed="false">
          </Fieldset>
          -->
          <div class="row w-100" >
            <div class="colum w-100">
              <Panel v-if="this.sectionsElementView.historial" class="mb-4 mb-xl-3" header="Historial" :toggleable="true" :collapsed="true">
                  <!-- 
                  <template #icons>
                      <button class="p-panel-header-icon p-link mr-2" @click="toggle">
                        <span class="pi pi-cog"></span>
                      </button>
                  </template>
                    -->

                  <div class="row" style="margin-top: 10px">
                    <div class="col-12">
                      <template v-if="datosEquipo.historial?.length === 0">
                        Sin cambios registrados.
                      </template>
                      <template v-else>
                        <DataTable
                          :value="datosEquipo.historial"
                          :paginator="datosEquipo.historial?.length > 5"
                          :rows="10"
                          :rowsPerPageOptions="[10, 25, 50]"
                          v-model:filters="filtersTableHistorial"
                          filterDisplay="menu"
                          :sortable="true"
                          :globalFilterFields="['fecha', 'estado', 'observacion']"
                          responsiveLayout="scroll"
                        >
                          <Column header="Fecha">
                            <template #body="body">
                              {{ formatDate(body.data.fecha, 'DD-MM-YYYY') }}
                            </template>
                          </Column>
                          <Column field="estado" header="Estado">
                            <template #body="body">
                              <span style="text-transform: capitalize">
                                {{ body.data.estado.toLowerCase() }}
                              </span>
                            </template>            
                          </Column>
                          <Column field="observacion" header="Observación"></Column>
                          <Column field="user" header="Realizada por">
                            <template #body="body">
                              {{ processUser(body.data.user) }}
                            </template>
                          </Column>
                        </DataTable>
                      </template>
                    </div>
                  </div>

                  <div v-if="useremail">
                    <p>Agregar comentarios</p>
                    <Button label="Agregar comentario" class="p-button-raised p-button-info" @click="showModalAgregarComentario = true"/>
                    <!-- <Button label="Reportar falla equipo" class="p-button-raised p-button-danger" @click="reportarFalla"/> -->
                  </div>
                  <div v-else  class="my-3">
                    <Button label="¿desea agregar un comentario?" class="p-button-raised p-button-info" @click="login"/>
                  </div>
              </Panel>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-lg-4">
          <div class="row" >
            <div class="colum">
              <stats-card
                v-if="this.sectionsElementView.fichaProveedor"
                title="Ficha Proveedor"
                type="gradient-orange"
                sub-title=""
                icon="ni ni-support-16"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <div class="row" >
                    <div class="column" >
                      <table >
                        <tbody>
                          <tr><td style="padding-right:10px"><b>Proveedor</b></td><td>{{ datosEquipo.proveedor }}</td></tr>
                          <tr v-if="datosEquipo.IDProveedor"><td style="padding-right:10px"><b>ID Proveedor</b></td><td>{{ datosEquipo.IDProveedor }}</td></tr>                    
                          <tr><td style="padding-right:10px"><b>Nombre Contacto</b></td><td>{{ datosEquipo.contactoProveedor }}</td></tr>
                          <tr><td style="padding-right:10px"><b>Telefono</b></td><td>{{ datosEquipo.fonoProveedor }}</td></tr>
                          <tr><td style="padding-right:10px"><b>Email</b></td><td>{{ datosEquipo.emailProveedor }}</td></tr>

                          <!-- <tr><td><b>Contrato</b></td><td>{{datosEquipo.idContrato.split("/")[0]}}</td></tr> -->
                          <!-- <tr><td><b>Fono</b></td><td>{{datosEquipo.idContrato.split("/")[1]}}</td></tr> -->
                          <!-- <tr><td><b>Email</b></td><td>{{datosEquipo.idContrato.split("/")[2]}}</td></tr> -->

                          <!-- <tr><td><b>Identificador {{ this.clientName }}</b></td><td>{{datosEquipo.ID}}</td></tr> -->

                          <!-- <tr><td><b>Número de serie</b></td><td>{{datosEquipo.numeroSerie}}</td></tr> -->

                          <!-- <tr><td><b>Nombre del Equipo</b></td><td>{{datosEquipo.nombreEquipo}}</td></tr> -->
                          <!-- <tr><td><b>Descripción del Equipo</b></td><td>{{datosEquipo.descripcion}}</td></tr> -->

                          <tr v-if="datosEquipo.fabricante"><td style="padding-right:10px"><b>Fabricante</b></td><td>{{ datosEquipo.fabricante }}</td></tr>
                          <tr v-if="datosEquipo.modelo"><td style="padding-right:10px"><b>Modelo Equipo</b></td><td>{{ datosEquipo.modelo }}</td></tr>
                          <tr v-if="datosEquipo.comentarios"><td style="padding-right:10px"><b>Comentarios</b></td><td>{{ datosEquipo.comentarios }}</td></tr>

                          <!-- <tr><td><b>Año fabricacion</b></td><td>{{datosEquipo.anoFabricacion}}</td></tr> -->

                          <!-- <tr><td><b>Fecha de PES</b></td><td>{{datosEquipo.fechaPES}}</td></tr> -->
                          <!-- 
                        <tr><td><b>Manuales Técnicos</b></td><td>
                          <base-button
                            v-model="LinkArchivo"
                            icon="pi pi-paperclip"
                            size="sm"
                            type="secondary"
                            :id="JSON.stringify(datosEquipo.listAdjuntos)"
                            @click="showUploadFiles('', $event)"></base-button>
                        </td></tr>
                        -->
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="mb-4 my-xl-5">
                    <table>
                      <tbody>
                        <tr v-if="datosEquipo.proyecto" style="text-align: center">{{datosEquipo.proyecto}}</tr>
                        <tr v-if="datosEquipo.urlQR" class="d-flex align-items-center justify-content-center"><qrcode-vue class="mb-4 my-xl-1" :value="datosEquipo.urlQR" :size="200" level="H"/></tr>
                        <tr v-else class="d-flex align-items-center justify-content-center"><qrcode-vue class="mb-4 my-xl-1" :value="qrURL" :size="200" level="H"/></tr>
                        <tr v-if="datosEquipo.TAG" style="text-align: center">{{datosEquipo.TAG}}</tr>
                        <tr v-if="datosEquipo.nombreCortoEquipo" style="text-align: center">{{datosEquipo.nombreCortoEquipo}}</tr>
                        <tr v-else-if="datosEquipo.nombreEquipo" style="text-align: center">{{datosEquipo.nombreEquipo}}</tr>
                      </tbody>
                    </table >
                  </div>
                </template>
              </stats-card>
          </div>
          </div>
          <div class="row" >
            <div class="colum">
              <stats-card
                v-if="this.sectionsElementView.fichaTecnica"
                title="Ficha Técnica"
                type="gradient-orange"
                sub-title=""
                icon="ni ni-support-16"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <div class="row" >
                    <div class="column" >
                      <table >
                        <tbody>
                          <!-- <tr><td><b>Identificador {{ this.clientName }}</b></td><td>{{datosEquipo.ID}}</td></tr> -->
                          <tr><td><b>Número de serie</b></td><td>{{ datosEquipo.numeroSerie }}</td></tr>
                          <!-- <tr><td><b>Nombre del Equipo</b></td><td>{{datosEquipo.nombreEquipo}}</td></tr> -->
                          <!-- <tr><td><b>Descripción del Equipo</b></td><td>{{datosEquipo.descripcion}}</td></tr> -->
                          <tr><td><b>Fabricante</b></td><td>{{ datosEquipo.fabricante }}</td></tr>
                          <tr><td><b>Proveedor</b></td><td>{{ datosEquipo.proveedor }}</td></tr>
                          <tr><td><b>Modelo Equipo</b></td><td>{{ datosEquipo.modelo }}</td></tr>
                          <!-- <tr><td><b>Comentarios (tension kV)</b></td><td>{{datosEquipo.comentarios}}</td></tr> -->
                          <tr><td><b>Comentarios</b></td><td>{{ datosEquipo.comentarios }}</td></tr>
                          <tr><td><b>Año fabricacion</b></td><td>{{ datosEquipo.anoFabricacion }}</td></tr>
                          <!-- <tr><td><b>Fecha de PES</b></td><td>{{datosEquipo.fechaPES}}</td></tr> -->
                          <tr><td><b>Manuales Técnicos</b></td><td>
                            <base-button
                              v-model="LinkArchivo"
                              icon="pi pi-paperclip"
                              size="sm"
                              type="secondary"
                              :id="JSON.stringify(datosEquipo.listAdjuntos)"
                              @click="showUploadFiles('', $event)"></base-button>
                          </td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
              </stats-card>
          </div>
          </div>
        </div>
        
      </div>
    </div>

  </div>
</template>
<script>
import firebase from 'firebase';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import Button from 'primevue/button';
import QrcodeVue from 'qrcode.vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
// import Fieldset from 'primevue/fieldset';
import Panel from 'primevue/panel';
import Message from 'primevue/message';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';

import { downloadFileFromURL, formatDate } from "@/components/utils";
import { getUserKeyAndType } from "@/model/functions";
import ModalCargaArchivos from '../components/Custom/ModalCargaArchivos.vue';

import 'primeflex/primeflex.css';

export default {
    components: {
      Button,
      QrcodeVue,
      DataTable,
      Column,
      Dialog,
      Panel,
      Message,
      Textarea,
      Toast,
      ModalCargaArchivos
  },
  data() {
    return {
      usertype: null,
      useremail: "",
      userDisplayName: "",
      showModalDetalleArchivos: false,
      listUploadedFiles: [],
      allFiles: [],

      loading: true,
      notFound: false,

      showModalReportarFalla: false,
      showModalAgregarComentario: false,
      showModalUploadFiles: false,
      showModalUploadPhotos: false,
      showModalUploadManuales: false,
      showModalUploadDocs: false,

      observacion: "",

      totalitems:0,
      totalCommissioning:0,
      total1YearOperation:0,
      totalCapital:0,

      elementid:this.$route.params.elementid,

      datosEquipo: {},
      qrURL: "",

      Equipment:"",
      POrder:"",
      Supplier:"",
      SpareType:"",
      PartNumber:"",
      Status: "",
      StatusType: "",
      Completion: "",

      timestampLastAction: 0,
    };
  },
  methods:
  {
    formatDate,

    isDuplicatedCall()
    {
      //ToDo: resolver tema de llamada doble
      var timestamp = Date.now();
      console.log("deltaT: " + (timestamp - this.timestampLastAction));
      if(timestamp - this.timestampLastAction > 2000)
      {
        this.timestampLastAction = timestamp;
        return false;
      }
      else
      {
        console.log("----- double call exit -----");
        return true;
      }        
    },    


    toggle(event) {
        this.$refs.menu.toggle(event);
    },

    processUser(user)
    {
      return user;
    },

    login()
    {
        this.$router.push({ name: "login", params: { path: "Detalle equipo", elementid: this.elementid } });
    },

    reportarFalla(comentarioFalla){
      console.log("reportarFalla::%s/%s", this.db_ref_inventario, this.elementid);

      if(this.isDuplicatedCall()) return;

      let fechaReporte = Date.now();
      firebase.database().ref(this.db_ref_inventario + "/" + this.elementid).update({ falla:true, fechaFalla: fechaReporte});

      const numSolicitud = String(fechaReporte).slice(0, 10);

      var newPost = firebase.database().ref(this.db_ref_solicitudes).push();
      newPost.set({
        numSolicitud: numSolicitud,
        elementid: this.elementid,
        fecha: fechaReporte,
        estado: "pendiente",
        reporteFalla: comentarioFalla,
        // reportadoPor: this.useremail,
        reportadoPor: this.userDisplayName,
        ...this.datosEquipo
      });

      const keySolicitud = newPost.key;

      var newPost = firebase.database().ref(this.db_ref_inventario + "/" + this.elementid + "/historial").push();
      newPost.set({
        fecha: fechaReporte,
        estado: "Falla",
        observacion: 'Se genera una solicitud de ejecución de garantía (solicitud nº ' +  numSolicitud + ')',
        user: this.userDisplayName,
      });

      try { //Envio de Mail
        const urlSolicitud = this.urlBase + "/#/solicitudes/" + keySolicitud;

        var htmlEmail= 'Se reporta falla en equipo en garantía ' + this.datosEquipo.nombreEquipo + ".<br/>"
            + 'Se ha generado la solicitud <strong>' + numSolicitud + '</strong>, la cual ha sido asignada asignada a ' 
            + this.datosEquipo.ingenieroContrato + " (" + this.datosEquipo.mailIngenieroContrato + ")" 
            +'<br>Para acceder a la solicitud haga click en:  <a href="'+ urlSolicitud +'">solicitud nº '+ numSolicitud +'</a>';
        var textoEmail = 'Se reporta falla en equipo en garantía ' + this.datosEquipo.nombreEquipo + "."
            + 'Se ha generado la solicitud ' + numSolicitud + ', la cual ha sido asignada asignada a ' 
            + this.datosEquipo.ingenieroContrato + " (" + this.datosEquipo.mailIngenieroContrato + ")";

        const msg = {
          to: this.datosEquipo.mailIngenieroContrato, //'maguilar@gc-hub.tech',
          from: 'e-garant@pcvuelat.com',
          subject: 'Nueva solicitud de ejecución de garantía (solicitud nº ' +  numSolicitud + ')',
          text: textoEmail,
          html: htmlEmail ,
        }

        let sendEmail = firebase.functions().httpsCallable('sendEmail');
        sendEmail(msg)
        .then((result) => {
          if(result.data && result.data.result == true)
          {
            console.log("sendEmail result ->", result);
          }
        })
        .catch((error) => {
          console.log(error);
        })
      }
      catch(error)
      {
        console.log(error);
      }

      this.$toast.add({
        severity: "success",
        summary: "Falla reportada",
        detail:'Se ha generado las solicitud ' + numSolicitud + ' al area de soporte, asignada a ' 
          + this.datosEquipo.ingenieroContrato + " (" + this.datosEquipo.mailIngenieroContrato + ")",
        life: 5000,
      });
      this.showModalReportarFalla = false;
      this.observacion = "";
    },

    agregarComentario(comentario, estado){
      console.log("agregarComentario::%s/%s", this.db_ref_inventario, this.elementid);

      var newPost = firebase.database().ref(this.db_ref_inventario + "/" + this.elementid + "/historial").push();
      newPost.set({
        fecha: Date.now(),
        estado: estado,
        observacion: comentario,
        user: this.userDisplayName,
      });
      this.$toast.add({
        severity: "success",
        summary: "comentario agregado",
        // detail:'Se ha generado una solicitud al area de soporte',
        life: 5000,
      });
      this.showModalAgregarComentario = false;
      this.observacion = "";
    },

    downloadFile: function(body, event) {
      event.preventDefault();
      if(this.isDuplicatedCall()) return;
      console.log('downloadFile--------');      
      downloadFileFromURL(body.data.url.url, body.data.url.name);      
    },

    showUploadFiles: function(category, event) {
      event.preventDefault();
      if(this.isDuplicatedCall()) return;
      //console.log("showUploadFiles---------" + event.currentTarget.id);

      this.listUploadedFiles.length = 0;

      this.listUploadedFiles = Object.values(this.allFiles).filter(item => {
        return item.categoria === category;
      });

      console.log("---filtrado---")
      console.log(this.listUploadedFiles);

      // if(this.datosEquipo.listAdjuntos && this.datosEquipo.listAdjuntos !== "NA")
      // {
      //   var items = JSON.parse(event.currentTarget.id);
      //   // var items = JSON.parse(this.datosEquipo.listAdjuntos);
      //   // this.listUploadedFiles = JSON.parse(event.currentTarget.id);

      //   items.forEach(element => {
      //     this.listUploadedFiles.push( {
      //       NombreArchivo:element.nombre,
      //       CategoriaArchivo:element.categoria,
      //       FechaCarga:formatDate(element.fecha, 'DD-MM-YYYY'),
      //       LinkArchivo:JSON.stringify({downloadLink:element.url, nombreArchivo:element.nombre}),
      //     });
      //   });
      // }

      this.showModalDetalleArchivos = true;
    },
    uploadFiles: function(event) {
      console.log("entra a Upload Files");

      
      this.showModalUploadFiles = true;
    },
    uploadPhotos: function(event) {
      console.log("entra a uploadPhotos");

      
      this.showModalUploadPhotos = true;
    },
    uploadManuales: function(event) {
      console.log("entra a uploadManuales");

      
      this.showModalUploadManuales = true;
    },
    uploadDocs: function(event) {
      console.log("entra a uploadDocs");

      
      this.showModalUploadDocs = true;
    },
    buscarArchivos(elementid){
      console.log("entra a buscarArchivos")
      const cargasRef = firebase.database().ref('cargas');
      
      const filterById = cargasRef.orderByChild('id').equalTo(elementid);
      
      filterById.on('value', snapshot =>{
        this.allFiles = snapshot.val()
        
      })
      
    }
  },
  mounted() 
  {
    //READING PARAMETER
    console.log(this.elementid);

    if (this.elementid =="-MyhpzHfs5QZf2xKpGPx")
    {
      window.location.href = "https://e-garant-bhp.pcvuelat.com/#/element/-Ndp112jAnvqCzhXH209"
      // window.location = url;
      return;
    }
        
    this.qrURL = this.urlBase + "/#/cl/" + this.elementid; //URL dinamica (se usa si es que dato de URL real instalado no está en la DB)

    firebase.database().ref(this.db_ref_inventario + "/" + this.elementid).on('value', (childSnapshot) => { 
      if(childSnapshot.exists())
      {
        this.datosEquipo = childSnapshot.val();
        console.log("this.datosEquipo", this.datosEquipo);
        this.loading = false;
      }
      else
      {
        this.notFound = true;
        this.loading = false;
      }
    });
  },

  created()
  {
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.useremail = user.email;
        console.log("USER SIGNED");

        getUserKeyAndType(this.db_ref_users, user.email, (userKey, usertype, data) => {
          this.userDisplayName = data.nombres + " " + data.apellidos;
          this.usertype = usertype;
        });
      } else {
        unsuscribe();
        console.log("USER NOT SIGNED"); //sólo puede ver info
      }
    });
    this.buscarArchivos(this.elementid);
    console.log("----Cargas----")
    console.log(this.listUploadedFiles)
    
  },


};
</script>

<style lang="scss" scoped>
.shadow-box {
    min-height: 100px;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin: 2rem;
    border-radius: 4px;
    background-color: var(--surface-e);
}

.p-button {
    margin-right: .5rem;
}

.p-buttonset {
    .p-button {
        margin-right: 0;
    }
}

.sizes {
    .button {
        margin-bottom: .5rem;
        display: block;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.fieldset p {
    line-height: 1.5;
    margin: 0;
}
</style>
